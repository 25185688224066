import React, { createRef, useEffect, useState } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
  UncontrolledTooltip
} from 'reactstrap'
import { accountServices } from '../../../services/accountServices'
import { documentStatusColor, getCookie } from '../../../utils/constants'
import moment from 'moment/moment'

import ConfirmationModal from "../../../Components/Common/ConfirmationModal"
import fetchPDF from '../../../common/fetchPDF'
import SpinnerComponent from '../../../Components/Common/Spinner'

const AccountDocuments = ({ setIsLoading }) => {
  const [clinicalDocuments, setClinicalDocuments] = useState([])
  const [showDocumentUploadModal, setShowDocumentUploadModal] = useState(false)
  const [showDocumentDownloadModal, setShowDocumentDownloadModal] =
    useState(false)
  const [showDocumentDeleteModal, setShowDocumentDeleteModal] =
    useState(false)
  const [userUploadedDocuments, setUserUploadedDocuments] = useState([])
  const [selectedFiles, setselectedFiles] = useState([])
  const [documentId, setDocumentId] = useState()
  const [deleteDocumentId, setDeleteDocumentId] = useState()
  const [enableCheck, setEnableCheck] = useState(false)
  const [documentDescription, setDocumentDescription] = useState('')
  const [loading, setLoading] = useState(false)

  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchClinicalDocumentTypes(token)
  }, [])

  const documentUploadModal = () => {
    setShowDocumentUploadModal(!showDocumentUploadModal)
    handleCancelClick(0)
    setEnableCheck(false)
  }

  const documentDownloadModal = () => {
    setShowDocumentDownloadModal(!showDocumentDownloadModal)
  }

  const handleDownloadClick = id => {
    const filterdClinicalDocuments = clinicalDocuments.filter(_ => {
      return _.uuid === id
    })
    setUserUploadedDocuments(
      filterdClinicalDocuments[0].user_clinical_documents
    )
    documentDownloadModal()
    // if (userUploadedDocuments.length !== 0) {
    // } else {
    //   toast.error('You need to upload document first!')
    // }
  }

  const handleDeleteEvent = () => {
    setIsLoading(true)
    accountServices
      .deleteDocument(deleteDocumentId, token)
      .then(resp => {
        if (resp.success) {
          fetchClinicalDocumentTypes(token)
          setShowDocumentDeleteModal(false)
          toast.success(resp.message)
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handleUploadClick = (data, index) => {
    setDocumentId(data?.uuid)
    documentUploadModal()
    setDocumentDescription(data?.document_description)
  }

  const fetchClinicalDocumentTypes = token => {
    accountServices
      .getClinicalDocumentTypes(token)
      .then(resp => {
        if (resp.success) {
          setClinicalDocuments(resp?.data)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchUploadDocument = () => {
    if (selectedFiles.length !== 0) {
      setIsLoading(true)
      let formData = new FormData()
      formData.append('file', selectedFiles[0])

      let documentCategory = 'clinicalDocuments'
      accountServices
        .postUploadDocument(formData, documentId, documentCategory, token)
        .then(resp => {
          if (resp.success) {
            fetchClinicalDocumentTypes(token)
            toast.success(resp.message)
            documentUploadModal()
            setselectedFiles([])
          } else {
            toast.error(resp.message)
            documentUploadModal()
          }
        })
        .catch(err => console.log(err))
        .finally(() => setIsLoading(false))
    }
  }

  const handleAcceptedFiles = files => {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size)
      })
    )
    setselectedFiles(files)
  }

  const handleDownloadDocument = (pdf_uuid, status, originalFileName) => {
    setLoading(true)
    accountServices.downloadDocument(pdf_uuid, status, token)
      .then(resp => fetchPDF(resp, originalFileName))
      .catch(err => console.log(err))
      .finally(() => setLoading(false))
  }

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes'
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
  }

  const handleCancelClick = idx => {
    const copiedFiles = [...selectedFiles]
    copiedFiles.splice(idx, 1)
    setselectedFiles(copiedFiles)
  }


  const dropzoneRef = createRef()
  const openDialog = () => {
    // Note that the ref is set async,
    // so it might be null at some point
    if (dropzoneRef.current) {
      dropzoneRef.current.open()
    }
  }

  useEffect(() => {
    openDialog()
  }, [dropzoneRef])

  return (
    <>
      {/* ***Desktop view*** */}
      <Card className='d-none d-xl-block'>
        {loading && <SpinnerComponent />}
        <CardBody>
          <div className='d-flex align-items-center mb-4'>
            <h5 className='card-title flex-grow-1 mb-0'>Documents
              {/* <Badge color="light"  id="tooltipTop-desktop"> <i className="ri-error-warning-line text-dark"></i> 
            </Badge> */}
            </h5>

          {/* <UncontrolledTooltip placement="top" target="tooltipTop-desktop">Import your documents from other platforms to speed up this process</UncontrolledTooltip> */}
          </div>
          <Row>
            <Col lg={12}>
              <div className='table-responsive table-card'>
                <Table className='align-middle table-hover  table-nowrap mb-0'>
                  <thead className='table-light text-muted'>
                    <tr>
                      <th scope='col'>Title</th>
                      <th scope='col'>Requirement</th>
                      <th scope='col'>Status</th>
                      <th scope='col'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(clinicalDocuments || []).map((item, key) => (
                      <tr key={key}>
                        <td>
                          <div className='d-flex align-items-center'>

                            <div className='avatar-sm'>
                              <div
                                className={`avatar-title bg-soft-primary text-primary rounded-circle fs-20`}
                              >
                                <i className='ri-file-line'></i>
                              </div>
                            </div>
                            <div className='ms-3 flex-grow-1'>
                              <h5>
                                {item.document_type}
                              </h5>
                              {/* <h6 className='text-muted'>{item?.document_category || ''}</h6> */}
                            </div>
                          </div>
                        </td>
                        {/* <td>
                          <span className={`badge rounded-pill badge-soft-${documentStatusColor(
                            item?.document_status || 'NA'
                          )}`}>{item?.document_status || 'NA'}</span>
                        </td> */}
                        <td>
                          {
                            <Badge
                              color={documentStatusColor(
                                item?.document_status || 'NA'
                              )}
                              className='text-capitalize'
                            >
                              {item?.document_status || 'NA'}
                            </Badge>
                          }
                          {/* <span className={`badge rounded-pill badge-soft-${documentStatusColor(
                            item?.document_status || 'NA'
                          )}`}>{item?.document_status || 'NA'}</span> */}
                        </td>
                        <td>
                          {(item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'approved') ? (
                            <span className={`text-capitalize text-success`}>
                              Approved
                            </span>
                          ) : (item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'awaiting_approval') ? (
                            <span className={`text-capitalize text-${documentStatusColor('awaiting_approval')}`}>
                              Awaiting approval
                            </span>
                          ):(item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'expired') ? (
                            <span className={`text-capitalize text-${documentStatusColor('expired')}`}>
                              expired
                            </span>
                          ):(item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'rejected') ? (
                            <span className={`text-capitalize text-${documentStatusColor('rejected')}`}>
                              rejected
                            </span>
                          ):(
                            <span className={`text-capitalize text-${documentStatusColor('NA')}`}>
                              Not Uploaded
                            </span>
                          )}
                        </td>
                        <td>
                          <div className='hstack gap-2'>
                            <Button
                              color='success'
                              size='sm'
                              className='btn-label btn-soft-success rounded-pill'
                              id={'tooltipDel' + item.uuid}
                              onClick={() => {
                                if (item?.user_clinical_documents.length > 0)
                                  handleDownloadClick(item.uuid)
                              }
                              }
                            >
                              <i className=' ri-download-cloud-fill label-icon align-middle fs-16 me-2'></i>
                              Manage
                            </Button>
                            <Button
                              color='primary'
                              size='sm'
                              className='btn-label btn-soft-primary rounded-pill'
                              id={'uploadDocs' + item.uuid}
                              onClick={() => handleUploadClick(item, key)}
                            >
                              <i className=' ri-upload-cloud-fill label-icon align-middle fs-16 me-2'></i>
                              Upload
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
        <div className='card-footer bg-transparent border-0'></div>
      </Card>


      {/* ***mobile view*** */}
      <div className='d-xl-none'>
        {loading && <SpinnerComponent />}
        <h4 className='page-title-box pt-0'>Documents
          {/* <Badge color="light"  id="tooltipTop-mobile"> <i className="ri-error-warning-line text-dark"></i> 
          </Badge> */}
          </h4>
        {/* <UncontrolledTooltip placement="top" target="tooltipTop-mobile">Import your documents from other platforms to speed up this process</UncontrolledTooltip> */}
        {(clinicalDocuments || []).map((item, key) => (
          <Card key={key}>

            <CardHeader className='bg-gradient bg-white'>


              <Row>
                <Col xs={8} className='fw-semibold'>
                  {item.document_type}
                </Col>
                <Col xs={4} className='text-end'>
                  <span className={`badge rounded-pill badge-soft-${documentStatusColor(
                    item?.document_status || 'NA'
                  )}`}>{item?.document_status || 'NA'}</span>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>

              {/* <Row>
                <Col  className='text-muted' xs={12}>
                {item?.document_category || ''}
                </Col> */}
                {/* <Col className='text-end' xs={7}>
                  
                </Col> */}
              {/* </Row> */}

              <Row>
                <Col className='text-muted'>Document status:

                </Col>
                <Col className='text-end'>
                {(item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'approved') ? (
                            <span className={`text-capitalize text-success`}>
                              Approved
                            </span>
                          ) : (item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'awaiting_approval') ? (
                            <span className={`text-capitalize text-${documentStatusColor('awaiting_approval')}`}>
                              Awaiting approval
                            </span>
                          ):(item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'expired') ? (
                            <span className={`text-capitalize text-${documentStatusColor('expired')}`}>
                              expired
                            </span>
                          ):(item.user_clinical_documents || []).some(status => status?.document_statuses?.slice(-1)[0]?.status === 'rejected') ? (
                            <span className={`text-capitalize text-${documentStatusColor('rejected')}`}>
                              rejected
                            </span>
                          ):(
                            <span className={`text-capitalize text-${documentStatusColor('NA')}`}>
                              Not Uploaded
                            </span>
                          )}
                  {/* {item.user_clinical_documents
                    .slice(-1)
                    ?.map((status, index) => (
                      <p
                        key={index}
                        className={`text-capitalize text-${documentStatusColor(
                          status?.document_statuses?.slice(-1)[0]
                            ?.status || 'NA')}`}
                      >
                        {
                          status?.document_statuses?.slice(-1)[0]
                            ?.status
                        }
                      </p>
                    ))} */}
                </Col>
              </Row>

            </CardBody>

            <CardFooter>
              <div className='hstack gap-2 pt-1 float-end'>
                <Button
                  color='success'
                  size='sm'
                  className='btn-label btn-soft-success rounded-pill'
                  id={'tooltipDel' + item.uuid}
                  onClick={() => {
                    if (item?.user_clinical_documents.length > 0)
                      handleDownloadClick(item.uuid)
                  }
                  }
                >
                  <i className=' ri-download-cloud-fill label-icon align-middle fs-16 me-2'></i>
                  Manage
                </Button>
                <Button
                  color='primary'
                  size='sm'
                  className='btn-label btn-soft-primary rounded-pill'
                  id={'uploadDocs' + item.uuid}
                  onClick={() => handleUploadClick(item, key)}
                >
                  <i className=' ri-upload-cloud-fill label-icon align-middle fs-16 me-2'></i>
                  Upload
                </Button>
              </div>
            </CardFooter>
          </Card>
        ))}

      </div>
      <Modal
        size='lg'
        isOpen={showDocumentDownloadModal}
        toggle={() => {
          documentDownloadModal()
        }}
      >
        <ModalHeader
          className="p-3 bg-airgp modal-title"
          toggle={() => {
            documentDownloadModal()
          }}
        >
          <div className="modal-title text-white">
            Manage documents
          </div>

        </ModalHeader>
        <ModalBody className='p-2'>
          <Col xl={12}>
            <div className='table-responsive'>
              <Table className='table-hover align-content-middle table-nowrap table-sm mb-0'>
                <thead className='text-muted'>
                  <tr>
                    {/* <th scope='col'>ID</th> */}
                    <th scope='col'>File Name</th>
                    <th scope='col'>Expiry Date</th>
                    <th scope='col'>Status</th>
                    <th scope='col'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {(userUploadedDocuments || []).map((_, index) => (

                    <tr key={index}>
                      {/* <td>{_?.uuid?.slice(0, 4) || 'NA'}</td> */}
                      <td>
                        <span className='d-inline-block text-truncate' style={{ maxWidth: '120px' }}>
                          {_?.original_file_name}
                        </span>
                        {/* <div className="row">
                          <div className="col-6 text-truncate">
                          {_?.original_file_name}
                          </div>
                        </div> */}
                      </td>
                      <td>{moment(_?.renewal_date).format('ll')}</td>
                      <td>
                        {/* <Badge
                          color={documentStatusColor(
                            _?.document_statuses?.slice(-1)[0]?.status
                              ? _?.document_statuses?.slice(-1)[0]?.status
                              : 'NA'
                          )}
                          className='text-capitalize '
                          pill
                        >
                          {_?.document_statuses?.slice(-1)[0]?.status}
                        </Badge> */}
                        <span
                          className={`badge text-capitalize  rounded-pill badge-soft-${documentStatusColor(
                            _?.document_statuses?.slice(-1)[0]?.status
                              ? _?.document_statuses?.slice(-1)[0]?.status
                              : 'NA'
                          )}`}>
                          {_?.document_statuses?.slice(-1)[0]?.status}
                        </span>
                      </td>
                      <td>
                        {/* <Link
                          to='#'
                          onClick={() => handleDownloadDocument(_?.uuid, "userclinicaldocuments", _?.original_file_name)}
                        >
                          <i className='ri-download-line fs-17 lh-1 align-content-middle'></i> Download
                        </Link> */}
                        <span className='btn btn-sm'
                          onClick={() => handleDownloadDocument(_?.uuid, "userclinicaldocuments", _?.original_file_name)}
                        >
                          <i className='ri-download-line text-primary fs-17 lh-1 align-content-middle'></i>
                          </span>
                        <span className='btn btn-sm'
                          onClick={() => {
                            setShowDocumentDownloadModal(false)
                            setShowDocumentDeleteModal(true)
                            setDeleteDocumentId(_?.uuid)
                            document.body.style.overflow = 'visible'
                          }}
                        >
                          <i className="ri-delete-bin-line text-danger fs-17 lh-1 align-content-middle"></i>
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Col>
          {/* </Row> */}
          {/* </div>
              </CardBody>
            </Card>
          </Col> */}
        </ModalBody>
        <div className='modal-footer'>
          <Button
            color='light'
            onClick={() => {
              documentDownloadModal()
            }}
          >
            Close
          </Button>
          {/* <Button color='primary'>Save changes</Button> */}
        </div>
      </Modal>
      <Modal
        size='lg'
        isOpen={showDocumentUploadModal}
        toggle={() => {
          documentUploadModal()
        }}
      >
        <ModalHeader
          className="p-3 bg-airgp modal-title"
          toggle={() => {
            documentUploadModal()
          }}
        >
          <div className="modal-title text-white h5">
            Upload Documents
          </div>

        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={12}>
              {/* <Card> */}
              {/* <CardHeader className='card-header'>
                  <h4 className='card-title mb-0'>Dropzone</h4>
                </CardHeader> */}
              {/* <CardBody> */}
              <p className='text-muted'>
                {documentDescription
                  ? documentDescription.split('<br>').map((item, idx) => (<React.Fragment key={idx}>{item}<br /></React.Fragment>))
                  : 'Please select document'}
              </p>
              <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles)
                }}
                // ref={dropzoneRef}
                maxFiles={1}
              // previewsContainer= {'file-previews'}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className='dropzone dz-clickable '>
                    <div
                      className=' dz-message needsclick'
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className='mb-3'>
                        <i className='display-4 cursor text-primary ri-upload-cloud-fill' />
                      </div>
                      <h4 className='cursor text-decoration-underline'>Drop files here or click here to upload.</h4>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className='list-unstyled mb-0' id='file-previews'>
                {selectedFiles.map((f, i) => {
                  return (
                    <Card
                      className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                      key={i}
                    >
                      <div className='p-2 bg-light'>
                        <Row className='d-flex align-items-center'>
                          <Col className='col-auto'>
                            <i className="ri-file-line ri-3x"></i>
                          </Col>
                          <Col className='text-truncate'>
                            {/* <Link
                              to='#'
                              className='text-muted font-weight-bold'
                            > */}
                              {f.name}
                            {/* </Link> */}
                            <p className='mb-0'>
                              <strong>{f.formattedSize}</strong>
                            </p>
                          </Col>
                          <Col className='cursor col-1'>
                            <i
                              className='ri-close-circle-line float-end fs-24'
                              onClick={() => handleCancelClick(i)}
                            ></i>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  )
                })}
              </div>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </ModalBody>
        <div className='modal-footer'>
          <div className='form-check flex-fill mb-4'>
            <input
              className='form-check-input'
              type='checkbox'
              id={`enableCheck`}
              onChange={e => {
                setEnableCheck(e.target.checked)
              }}
              value={enableCheck}
            // checked={enableCheck}
            />
            <label
              className='form-check-label text-decoration-underline'
              htmlFor={`enableCheck`}
            >
              {`I agree to share my personal documents with prospective organisations`}
            </label>
          </div>
          <div className='flex-fill mt-3'>
          <p className='text-muted small'><i>You can upload multiple documents by clicking upload again</i></p>
          </div>
          
          <Button color='primary' onClick={() => {
            if (selectedFiles.length === 0) {
              toast.error('Please select the document')
            } else if (enableCheck) {
              fetchUploadDocument()
            } else {
              toast.error('Please check the consent box to proceed')
            }
          }}>
            Upload
          </Button>
          <Link
            to='#'
            className='btn w-sm btn-light'
            onClick={() => {
              setShowDocumentUploadModal(false)
              setEnableCheck(false)
            }}
          >
            Close
          </Link>
        </div>
      </Modal>
      <ConfirmationModal
        show={showDocumentDeleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => {
          setShowDocumentDeleteModal(false)
          document.body.style.overflow = 'visible'
        }}
        deleteMsg='Are you sure you want to delete this document ?'
        btnName='Delete'
      />
    </>
  )
}

export default AccountDocuments
