import React from 'react'
import { Link } from 'react-router-dom'
import { Col, Container, Row } from 'reactstrap'
// import { Link } from 'react-router-dom';

import CookieNotice from '../Components/Common/CookieNotice';
const getCookieAcceptanceFromLocalStorage = () => {
  return localStorage.getItem('cookie-acceptance') === 'true';
};



const Footer = () => {
  return (
    <React.Fragment>
      <footer className='footer mb-2'>
        <Container fluid>
          <Row >

            <Col sm={3}>{new Date().getFullYear()} © airGP</Col>
            <Col sm={9} >
              <div className='text-sm-end'>

                <a href="https://airgp.co.uk/terms/" className='text-muted' target='_blank' rel='noopener noreferrer'>
                  Terms & Conditions ᐧ
                </a>
                <a href="https://airgp.co.uk/privacy-policy/" className='text-muted' target='_blank' rel='noopener noreferrer'>
                  &nbsp;Privacy Policy ᐧ
                </a>
                <a href="https://airgp.co.uk/support/" className='text-muted' target='_blank' rel='noopener noreferrer'>
                &nbsp;Support
                </a>
              </div>
            </Col>
          </Row>

          {/* <CookieNotice getCookieAcceptance={getCookieAcceptanceFromLocalStorage} /> */}

        </Container>
      </footer>
    </React.Fragment>
  )
}

export default Footer
